import React, { useState } from 'react';

interface ImageComponentProps {
  data: string;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleShowDialog = () => {
    setIsOpen(!isOpen);
    console.log('clicked');
  };

  return (
    <div>
      <p onClick={handleShowDialog} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
        Click to view image
      </p>
      {isOpen && (
        <dialog
          className="dialog"
          style={{ position: 'absolute' }}
          open
          onClick={handleShowDialog}
        >  
       <img
            className="image"
            src={`data:image/png;base64,${data}`}
            onClick={handleShowDialog}
            alt="no image"
	style={{ maxWidth: '400px', maxHeight: '400px', width: 'auto', height: 'auto' }}
          />
        </dialog>
      )}
    </div>
  );
};

export default ImageComponent;
